import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import TextField, { getIcon } from 'views/TextField.js';

const DatePickerField = props => {

    const { dateTime, fieldStyle, icon, insetLabel, insetContainerStyle, insetLabelStyle, onDateChange, onRemoveDate, onValidate, placeholder, selected } = props;

    const [active, setActive] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const onFieldClick = () => {
        setActive(true);
        props.utils.datePicker.show({
            date: selectedDate,
            ...props,
            onDateChange: onInnerDateChange,
            onRemoveDate: onInnerRemoveDate,
            onClose: () => setActive(false)
        })
    }

    const onInnerDateChange = date => {
        setSelectedDate(date);
        if(typeof(onDateChange) === 'function') {
            onDateChange(date);
        }
    }

    const onInnerRemoveDate = () => {
        setSelectedDate(null);
        if(typeof(onRemoveDate) === 'function') {
            onRemoveDate();
        }
    }

    const formatDate = () => {
        if(!selectedDate) {
            return placeholder || 'Select a date...';
        }
        if(dateTime) {
            return moment(selectedDate).format('MMMM Do, YYYY [at] h:mma')
        }
        return moment(selectedDate).format('MMMM Do, YYYY');
    }

    const getLocalIcon = () => {
        if(insetLabel) {
            return null;
        }
        return getIcon(icon || 'calendar');
    }

    const getTextColor = () => {
        if(!selectedDate) {
            return Appearance.colors.subText();
        }
        return Appearance.colors.text();
    }

    useEffect(() => {
        setSelectedDate(selected ? moment(selected) : null);
    }, [selected]);

    return (
        <div
        onClick={onFieldClick}
        className={`dummy-field ${active ? 'active' : ''} ${window.theme} cursor-pointer`}
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
            position: 'relative',
            height: 35,
            ...props.style
        }}>
            {getLocalIcon() && (
                <div style={{
                    paddingRight: 8,
                    marginLeft: 4
                }}>
                    <i
                    className={getLocalIcon()}
                    style={{
                        marginRight: 8,
                        color: Appearance.colors.softBorder(),
                        fontSize: 13
                    }}/>
                </div>
            )}
            {insetLabel && (
                <div style={{
                    marginRight: 8,
                    paddingRight: 8,
                    borderRight: `1px solid ${Appearance.colors.softBorder()}`,
                    ...insetContainerStyle
                }}>
                    <span style={{
                        display: 'block',
                        fontSize: 10,
                        fontWeight: '600',
                        color: Appearance.colors.subText(),
                        ...insetLabelStyle
                    }}>{insetLabel}</span>
                </div>
            )}
            <span style={{
                fontSize: 12,
                fontWeight: 500,
                color: getTextColor(),
                width: '100%',
                flexGrow: 1,
                ...fieldStyle
            }}>{formatDate()}</span>
            {typeof(onValidate) === 'function' && onValidate(selectedDate) && (
                <img
                src={'images/checkmark-small-green.png'}
                style={{
                    width: 15,
                    height: 15,
                    objectFit: 'contain',
                    marginLeft: 8
                }} />
            )}
        </div>
    )
}
export default DatePickerField;
