import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const ImagePickerField = ({  fileTypes, onChange, requirements, style, utils, value }) => {

    const [image, setImage] = useState(value);
    const [label, setLabel] = useState('');

    const getFileTypes = () => {
        if(!fileTypes || !Array.isArray(fileTypes)) {
            return 'image/png, image/jpeg';
        }
        return fileTypes.map(type => {
            switch(type) {
                case 'jpg':
                return 'image/jpeg';

                case 'png':
                return 'image/png';

                default:
                return null;
            }
        }).filter(type => {
            return type !== null;
        }).join(', ');
    }

    const onFileChange = evt => {

        let { files } = evt.target;
        if(files && files.length > 0) {

            let file = files[0];
            const reader = new FileReader();
            reader.onload = async data => {
                try {
                    let image = {
                        data: data.target.result,
                        file_name: file.name,
                        file_type: file.type
                    };
                    await onValidateImage(image);

                    setImage(image);
                    setLabel(image.file_name);
                    if(typeof(onChange) === 'function') {
                        onChange(image);
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue processing your image. ${e.message || 'An unknown error occurred'}`
                    })
                }
            };
            reader.readAsDataURL(files[0]);
        }
    }

    const onImageOptions = () => {

        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove Image',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                setImage(null);
                setLabel('No image selected...');
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
            }
        })
    }

    const onValidateImage = async image => {
        return new Promise((resolve, reject) => {

            // immediately resolve if no image requirements are found
            if(!requirements) {
                resolve();
                return;
            }

            // check that image falls within dimension requirements if applicable
            if(requirements.dimensions) {
                let { height, maxHeight, minHeight, maxWidth, minWidth, width } = requirements.dimensions;
                let component = new Image();
                component.src = image.data;
                component.onload = function() {
                    try {
                        if(this.width > maxWidth || this.height > maxHeight) {
                            throw new Error(`Your image size must be no larger than ${maxWidth} x ${maxHeight}. ${image.file_name} is ${this.width} x ${this.height} and is too large to use.`);
                        }
                        if(this.width < minWidth || this.height < minHeight) {
                            throw new Error(`Your image size must be at least ${minWidth} x ${minHeight}. ${image.file_name} is ${this.width} x ${this.height} and is too small to use.`);
                        }
                        if(this.width < width || this.height < height) {
                            throw new Error(`Your image size must be at least ${width} x ${height}. ${image.file_name} is ${this.width} x ${this.height} and is too small to use.`);
                        }
                        resolve();
                    } catch(e) {
                        reject(e);
                    }
                };
                return;
            }
            resolve();
        })
    }

    useEffect(() => {
        setImage(value);
        setLabel(value ? (value.name || value.file_name) : 'No image selected...');
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            {image && (
                <div
                className={'text-button'}
                style={{
                    marginRight: 4
                }} onClick={onImageOptions}>
                    <img
                    src={image.url || image.data}
                    style={{
                        width: 45,
                        height: 35,
                        borderRadius: '0.25rem',
                        objectFit: 'cover',
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}/>
                </div>
            )}
            <div
            className={`custom-file ${window.theme}`}
            style={{
                flexGrow: 1,
                overflow: 'hidden'
            }}>
                <input
                type={'file'}
                className={`custom-file-input ${window.theme}`}
                accept={getFileTypes()}
                onChange={onFileChange} />
                <label
                className={`custom-file-label ${window.theme}`}
                style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: image ? Appearance.colors.text() : Appearance.colors.subText()
                }}>{label}</label>
            </div>
        </div>
    )
}

export default ImagePickerField;
