import React, { useRef, useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';

import { AsYouTypeFormatter } from 'google-libphonenumber';
import Appearance from 'styles/Appearance.js';
import PhoneInput from 'react-phone-number-input';
import ProgressBar from 'views/ProgressBar.js';
import Utils from 'files/Utils.js';

const TextField = React.forwardRef(({
    append, appendContent, autoCapitalize, autoComplete, autoCorrect, className, containerStyle, expandWithText, fieldStyle, format, focused, icon, iconStyle, insetLabel, insetLabelStyle, insetContainerStyle, isSecure, isValid, loading, multiline, onChange, onBlur, onFocus, onKeyDown, onClick, onValidate, placeholder, prepend, prependContent, spellCheck, useDelay, value
}, ref) => {

    const fieldRef = useRef(null);
    const [active, setActive] = useState(focused);
    const [countryCode, setCountryCode] = useState(null);
    const [formatter, setFormatter] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const onFieldClick = () => {
        setActive(true);
        if(typeof(onClick) === 'function') {
            onClick();
        }
    }

    const onFieldBlur = () => {
        setActive(false);
        if(typeof(onBlur) === 'function') {
            onBlur();
        }
    }

    const onPhoneInputTextChange = text => {

        // clear previous timeout if present
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        // set text with timeout if applicable
        setText(text);
        setTextTimeout(setTimeout(() => {
            if(typeof(onChange) === 'function') {
                onChange(text);
            }
        }, useDelay === true ? 250 : 0));
    }

    const onTextChange = evt => {

        // clear previous timeout if present
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        let newText = evt.target.value.replace(prepend || '', '').replace(append || '', '');
        switch(format) {
            case 'double':
            newText = parseFloat(newText.replace(/[^\d.-]/g,''));
            break;

            case 'integer':
            newText = parseInt(newText.replace(/[^\d.-]/g,''));
            break;

            case 'number':
            newText = newText.replace(/[^\d.-]/g,'');
            break;

            default:
            if(typeof(format) === 'function') {
                newText = format(newText);
            }
        }

        // set text with timeout if applicable
        setText(newText);
        setTextTimeout(setTimeout(() => {
            if(typeof(onChange) === 'function') {
                onChange(newText);
            }
        }, useDelay === true ? 250 : 0));
    }

    const onKeyDownPress = evt => {
        let keyCode = evt.keyCode;
        let value = evt.target.value;
        setTimeout(() => {
            if(typeof(onKeyDown) === 'function') {
                if(keyCode === 8) {
                    onKeyDown('backspace');
                    return;
                }
                onKeyDown(value);
            }
        }, useDelay === false ? 0 : 250);
    }

    const getContent = () => {
        if(format !== 'phone_number') {
            return getField();
        }
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active':''} ${className || ''}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                position: 'relative',
                height: expandWithText ? 'auto' : 35,
                minHeight: 35,
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                ...containerStyle
            }}>
                <PhoneInput
                value={text}
                defaultCountry={countryCode}
                placeholder={placeholder}
                international={true}
                countryCallingCodeEditable={false}
                onChange={onPhoneInputTextChange}
                style={{
                    width: '100%'
                }}/>
                {appendContent}
            </div>
        )
    }

    const getField = () => {
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active':''} ${className || ''}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                position: 'relative',
                height: expandWithText ? 'auto' : 35,
                minHeight: 35,
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                ...containerStyle
            }}>
                {getIcon(icon) && (
                    <div style={{
                        paddingRight: 8,
                        marginLeft: 4
                    }}>
                        <i
                        className={getIcon(icon)}
                        style={{
                            color: Appearance.colors.icon(),
                            fontSize: 13,
                            ...iconStyle
                        }}/>
                    </div>
                )}
                {insetLabel && (
                    <div style={{
                        marginRight: 8,
                        paddingRight: 8,
                        borderRight: `1px solid ${Appearance.colors.softBorder()}`,
                        ...insetContainerStyle
                    }}>
                        <span style={{
                            fontSize: 10,
                            fontWeight: '600',
                            color: Appearance.colors.subText(),
                            ...insetLabelStyle
                        }}>{insetLabel}</span>
                    </div>
                )}
                {getFieldComponent()}
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 2
                }}>
                    <div style={{
                        display: loading ? 'block' : 'none',
                        overflow: 'hidden',
                        borderRadius: 2,
                        height: 2
                    }}>
                        <ProgressBar />
                    </div>
                </div>
                {appendContent}
                {typeof(onValidate) === 'function' && onValidate(text) && (
                    <img
                    src={'images/checkmark-small-green.png'}
                    style={{
                        width: 15,
                        height: 15,
                        objectFit: 'contain',
                        marginLeft: 8
                    }} />
                )}
            </div>
        )
    }

    const getFieldComponent = () => {
        if(typeof(onClick) === 'function') {
            return (
                <span
                onClick={onFieldClick}
                className={'cursor-pointer'}
                style={{
                    flexGrow: 1,
                    borderWidth: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                    backgroundColor: Appearance.colors.transparent,
                    ...fieldStyle
                }}>{value || placeholder}</span>
            )
        }
        if(isSecure || expandWithText !== true) {
            return (
                <input
                ref={ref}
                className={`dummy-field-input ${window.theme}`}
                onBlur={onFieldBlur}
                onClick={onFieldClick}
                onChange={onTextChange}
                onKeyDown={onKeyDownPress}
                value={(prepend || '') + (text || '') + (append || '')}
                placeholder={placeholder || ''}
                type={isSecure ? 'password' : 'text'}
                autoComplete={autoComplete || 'off'}
                autoCorrect={autoCorrect === false ? 'off':'on'}
                autoCapitalize={autoCapitalize === false ? 'off' : autoCapitalize}
                spellCheck={spellCheck === false ? 'false':'true'}
                inputMode={getInputMode()}
                style={{
                    border: 'none',
                    width: '100%',
                    height: 'auto',
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    backgroundColor: Appearance.colors.transparent,
                    ...fieldStyle
                }} />
            )
        }

        return (
            <textarea
            ref={ref}
            className={`dummy-field-input ${window.theme}`}
            onBlur={onFieldBlur}
            onClick={onFieldClick}
            onChange={onTextChange}
            onKeyDown={onKeyDownPress}
            value={(prepend || '') + (text || '') + (append || '')}
            placeholder={placeholder || ''}
            type={isSecure === true ? 'password':'text'}
            autoComplete={autoComplete || 'off'}
            autoCorrect={autoCorrect === false ? 'off':'on'}
            autoCapitalize={autoCapitalize === false ? 'off' : autoCapitalize}
            spellCheck={spellCheck === false ? 'false':'true'}
            style={{
                border: 'none',
                width: '100%',
                height: 'auto',
                minHeight: 125,
                fontSize: 12,
                fontWeight: 500,
                resize: 'none',
                verticalAlign: 'middle',
                color: Appearance.colors.text(),
                paddingTop: expandWithText ? 0 : 18.5,
                backgroundColor: Appearance.colors.transparent,
                ...fieldStyle
            }} />
        )
    }

    const getInputMode = () => {
        if([ 'integer' ].includes(format)) {
            return 'numeric';
        }
        if([ 'double', 'integer', 'number', 'phone_number' ].includes(format)) {
            return 'decimal';
        }
        return null;
    }

    const setupFormat = () => {
        if(format === 'phone_number') {
            try {
                // setup formatter for inline text formatting
                let formatter = new AsYouTypeFormatter(countryCode || 'US');
                setFormatter(formatter);

                // set default country code using navigator locale
                let locale = new Intl.Locale(navigator.language);
                setCountryCode(locale.region);
            } catch(e) {
                console.error(e.message);
                setCountryCode('US');
            }
        }
    }

    useEffect(() => {
        setupFormat();
    }, [format]);

    useEffect(() => {
        setActive(focused);
    }, [focused])

    useEffect(() => {
        setText(value || '');
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
        }}>
            {prependContent}
            {getContent()}
        </div>
    )
})

export default TextField;

export const TextFieldContainer = React.forwardRef(({ containerStyle, fieldStyle, icon, iconStyle, loading, onChange, onClick, value }, ref) => {

    const [active, setActive] = useState(false);

    const onFieldClick = async () => {
        try {
            setActive(true);
            if(typeof(onClick) === 'function') {
                onClick();
            }
            await Utils.sleep(1);
            setActive(false);

        } catch(e) {
            console.error(e.message);
        }
    }

    const getContent = () => {
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active':''}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                position: 'relative',
                height: 35,
                minHeight: 35,
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                ...containerStyle
            }}>
                {getIcon(icon) && (
                    <div style={{
                        paddingRight: 8,
                        marginLeft: 4
                    }}>
                        <i
                        className={getIcon(icon)}
                        style={{
                            color: Appearance.colors.icon(),
                            fontSize: 13,
                            ...iconStyle
                        }}/>
                    </div>
                )}
                <span
                onClick={onFieldClick}
                className={'cursor-pointer'}
                style={{
                    flexGrow: 1,
                    borderWidth: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                    backgroundColor: Appearance.colors.transparent,
                    ...fieldStyle
                }}>{value}</span>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 2
                }}>
                    <div style={{
                        display: loading ? 'block' : 'none',
                        overflow: 'hidden',
                        borderRadius: 2,
                        height: 2
                    }}>
                        <ProgressBar />
                    </div>
                </div>
            </div>
        )
    }

    return getContent();
})

export const getIcon = (icon) => {
    switch(icon) {

        case 'user':
        return 'fas fa-user';

        case 'spouse':
        return 'fas fa-house-user';

        case 'user-secret':
        return 'fas fa-user-secret';

        case 'email':
        return 'fas fa-envelope';

        case 'phone':
        return 'fas fa-phone';

        case 'calendar':
        return 'fas fa-calendar-alt';

        case 'house':
        return 'fas fa-home';

        case 'search':
        return 'fas fa-search';

        case 'lock':
        return 'fas fa-lock';

        case 'location':
        return 'fas fa-location-arrow';

        case 'payment':
        return 'fas fa-money-check-alt';

        case 'asterick':
        return 'fas fa-asterisk';

        case 'route':
        return 'fas fa-route';

        case 'guardian':
        return 'fas fa-user-friends';

        case 'title':
        return 'fas fa-signature';

        case 'description':
        return 'fas fa-stream';

        case 'featured':
        return 'fas fa-hand-holding-heart';

        case 'wifi':
        return 'fas fa-wifi';

        case 'pattern':
        return 'fas fa-th'

        case 'office':
        return 'fas fa-briefcase'

        default:
        return;
    }
}
