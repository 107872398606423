export const api_version = 1;
export const app_version = '1.0.3';
export const build = '2024.09.13.12.30';
export const dev_env = false;

export const aft_server = dev_env ? 'http://aft.local.192.168.4.40.nip.io' : 'https://aftplatform.com';
export const server = dev_env ? 'http://homesafealerts.local.192.168.4.40.nip.io' : 'https://homesafealerts.com';

export default {
    aft: {
        api_url: `${aft_server}/api/v2/`,
        server: aft_server
    },
    app_version: app_version,
    build: build,
    dev_env: dev_env,
    version: api_version,
    server: server,
    url: `${server}/api/v${api_version}`
}
